import React, { useState } from 'react';
import { Row, Col, Typography } from 'antd';
import { PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';

import { CarouselBanners } from './CarouselBanners';
import { CarouselServicios } from './CarouselServicios';
import { CarouselEventos } from './CarouselEventos';
import { SeccionEventos } from './SeccionEventos';

import '../../../assets/css/sitio/home.css';

import src_img_bascula_quienes_somos from '../../../assets/images/sitio/home/Maf_QuienesSomos_ICONO.png';
import img_alianza_art from '../../../assets/images/sitio/nosotros/alianzas/Maf_ART.jpg';
import img_alianza_axxis from '../../../assets/images/sitio/nosotros/alianzas/Maf_Axxis.jpg';
import img_alianza_dane from '../../../assets/images/sitio/nosotros/alianzas/Maf_Dane.jpg';
import img_alianza_inver from '../../../assets/images/sitio/nosotros/alianzas/Maf_Inver.jpg';
import img_alianza_manuel_barrera from '../../../assets/images/sitio/nosotros/alianzas/Maf_ManuelBarrera.jpg';
import img_alianza_san_roman from '../../../assets/images/sitio/nosotros/alianzas/Maf_SanRoman.jpg';
import img_doctora from '../../../assets/images/sitio/home/01_Maf_NuestroEquipo_DraFromow.jpg';
import { Transversalidad } from './Transversalidad';
import { Element } from 'react-scroll';

import banner8 from '../../../assets/images/sitio/banners/Domingo.jpg'


const { Title, Paragraph } = Typography;

export const Home = () => {

    const [show_texto_quienes_somos, setShowTextoQuienesSomos] = useState(false);
    const [tipo_servicio, setTipoServicio] = useState('privado');

    return (
        <>
            
            <Row style={{display:'non'}}>
                <Col xs={24}>
                    <img src={banner8} style={{width:'100%'}} />
                </Col>
                <Col xs={{span:22, offset:1}} sm={{span:22, offset:1}} md={{span:20, offset:2}} className="contenedor_quienes_somos">
                    <Row align="middle">
                        <Col xs={{span:24, offset:0}} md={{span:24,offset:0}}>
                            <Row>
                                <Col xs={{span:24, offset:0}} sm={{span:24, offset:0}}>
                                <Title className="titulo_quienes_somos">LA FIRMA</Title>
                                </Col>
                                <Col xs={{span:23, offset:0}} lg={{span:23, offset:0}}>
                                    <Paragraph className="texto_quienes_somos">
                                    En <strong>MAF & Asociados</strong> somos una firma de abogados y consultores, que surge como respuesta a una serie de necesidades desatendidas en el mercado, en materia Jurídica, Corporativa y Gubernamental entre otras. Integrada por un <strong>equipo multidisciplinario de abogados altamente calificados y por consultores especialistas en diversas áreas</strong>, todos enfocados a la generación de valor para nuestros clientes. Nos hemos consolidado como una firma legal y consultora, que proporciona soluciones Integrales / Transversales qué superan las expectativas de nuestros clientes en <strong>soluciones y resultados</strong>.
                                    </Paragraph>
                                </Col>
                            </Row>

                        </Col>
                        <Col xs={{span:0, offset:0}} md={{span:0,offset:0}} className="contenedor_img_bascula_quienes_somos">
                            <img alt="Quiénes somos" src={src_img_bascula_quienes_somos} className="img_bascula_quienes_somos" />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row style={{display:'non'}} className="contenedor_frase_doctora">
                <Col xs={{span:22, offset:1}} sm={{span:22, offset:1}} md={{span:20, offset:2}}>
                    <Row align="middle">
                    <Col xs={{span:12, offset:6}} lg={{span:6, offset:0}} >
                                <img src={img_doctora} className="img_frase_doctora" />
                                </Col>
                                <Col xs={{span:24, offset:0}} lg={{span:18, offset:0}}>
                                    <Paragraph className="texto_frase_doctora">
                                    <strong>Generamos soluciones integrales, efectivas y oportunas, a las necesidades de nuestros socios estratégicos.</strong>
                                    </Paragraph>
                                    <Element name="#transversalidad" > </Element>
                                    <Paragraph className="texto_frase_doctora_2">
                                    <i>Dra. María de los Ángeles Fromow</i>
                                    </Paragraph>
                                </Col>
                    </Row>
                    
                </Col>
            </Row>
            <Row className="contenedor_servicios" style={{display:'none'}}>
                <Col xs={{span:24, offset:0}} >
                    <CarouselServicios />
                </Col>
            </Row>
            <Row className="contendor_seccion_eventos" style={{display:'none'}}>
                <Col xs={{span:0, offset:0}} md={{span:20, offset:2}}>
                <Paragraph className="titulo_seccion_evento_movil" >PRÓXIMOS EVENTOS</Paragraph>
                    <p className="leyenda_eventos_movil">“Nos encontramos trabajando en el desarrollo de próximos eventos. ¡Espéralos!”</p>
                </Col>
                <Col xs={{span:24, offset:0}} md={{span:0, offset:0}}>
                    <Paragraph className="titulo_seccion_evento_movil" >PRÓXIMOS EVENTOS</Paragraph>
                    <p className="leyenda_eventos_movil">“Nos encontramos trabajando en el desarrollo de próximos eventos. ¡Espéralos!”</p>
                    
                </Col>
            </Row>
           <Transversalidad />
            {/*<Row align="middle" className="contenedor_seccion_alianzas">
                <Col xs={{span:0, offset:0}} md={{span:20, offset:2}}>
                    <Paragraph className="titulo_seccion_alianzas">NUESTRAS ALIANZAS</Paragraph>
                </Col>
                <Col xs={{span:24, offset:0}} md={{span:0, offset:0}} style={{textAlign:'center'}}>
                    <Paragraph className="titulo_seccion_alianzas">NUESTRAS ALIANZAS</Paragraph>
                </Col>
                <Col xs={{span:24, offset:0}} md={{span:20, offset:2}}>
                    <Row>
                        <Col xs={{span:8, offset:0}} md={{span:4, offset:0}} style={{textAlign:'center'}}>
                            <img src={img_alianza_san_roman} className="img_alianza" alt="San Roman" />
                        </Col>
                        <Col xs={{span:8, offset:0}} md={{span:4, offset:0}} style={{textAlign:'center'}}>
                            <img src={img_alianza_dane} className="img_alianza" alt="Dane" />
                        </Col>
                        <Col xs={{span:0, offset:0}} md={{span:4, offset:0}} style={{textAlign:'center'}}>
                            <img src={img_alianza_inver} className="img_alianza" alt="Inver Planning" />
                        </Col>
                        <Col xs={{span:8, offset:0}} md={{span:4, offset:0}} style={{textAlign:'center'}}>
                            <img src={img_alianza_axxis} className="img_alianza" alt="Axxis" />
                        </Col>
                        <Col xs={{span:8, offset:0}} md={{span:4, offset:0}} style={{textAlign:'center'}}>
                            <img src={img_alianza_art} className="img_alianza" alt="ART" />
                        </Col>
                        <Col xs={{span:8, offset:0}} md={{span:4, offset:0}} style={{textAlign:'center'}}>
                            <img src={img_alianza_manuel_barrera} className="img_alianza" alt="Manuel Barrera" />
                        </Col>
                    </Row>
                </Col>
    </Row>*/}
            
        </>
    );
}
